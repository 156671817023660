@import url(https://fonts.googleapis.com/css?family=Jost:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;

  scroll-behavior: auto;
  overflow-y: auto;
}

.b {
  border: 2px solid black;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.thumbs {
  display: none !important;
}

/* Hide the scrollbar */
body::-webkit-scrollbar {
  width: 0em;
  background-color: #f5f5f5;
}

.card {
  box-shadow: 3px 3px 3px rgb(155, 156, 158) !important;
}

.fb-root {
  position: fixed;
  top: 0;
  left: 0;
}
.b{
  border: 1px solid red;
}